import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/logos/nextbase-dark-logo.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/logos/nextbase-light-logo.png");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarClose"] */ "/vercel/path0/src/app/(dynamic-pages)/(authenticated-pages)/(application-pages)/_sidebar/_components/SidebarClose.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientShell"] */ "/vercel/path0/src/components/ApplicationLayoutShell/ClientShell.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppAdminLinkClient"] */ "/vercel/path0/src/components/NavigationMenu/AppAdminLink/AppAdminLinkClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppAdminPreviewLink"] */ "/vercel/path0/src/components/NavigationMenu/AppAdminLink/AppAdminPreviewClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Notifications"] */ "/vercel/path0/src/components/NavigationMenu/Notifications.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarOpen"] */ "/vercel/path0/src/components/NavigationMenu/SidebarOpen.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserNavPopover"] */ "/vercel/path0/src/components/NavigationMenu/UserNav/UserNavPopover.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarLink"] */ "/vercel/path0/src/components/SidebarLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeToggle"] */ "/vercel/path0/src/components/ThemeToggle.tsx");
